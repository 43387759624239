import React from 'react'
import Layout from '../components/containers/layout'
import ArticleList from '../components/blog/articleList'
import Navbar from '../components/nav/navbar'
import Footer from '../components/nav/footer'
import Seo from "../components/seo/seo"
import Hr from '../components/hr/hr.js'
import { getSrc } from "gatsby-plugin-image"

const seoImage = getSrc("../../static/blog/dog.jpg")

const Blog = () => (
  <Layout>
    <Seo title="Amicus blog"
         description="Specjalnie dla was nasi lekarze przygotowali zbiór porad dotyczących opieki nad zwierzętami"
         image={ seoImage }
    />
    <Navbar />
    <Hr nazwa={ "Blog, Amicus" }/>
    <ArticleList />
    <Footer />
  </Layout>
)
export default Blog;
