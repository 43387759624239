import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import './article.css'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Article = ( props ) => {

  const image = getImage(props.timage)

  return (
    <Link to={props.to} className="text-decoration-none" style={{color: "black"}}>
      <article key={props.id} className="background_article">
        <Container fluid >
          <Row className="m-sm-2 mt-sm-5">
            <Col md={{ span: 4}} className="align-self-center">
              <GatsbyImage image={image} style={{ display: 'block', height: '200px' }} alt={props.title} />
            </Col>
            <Col md={{ span: 8}}>
              <p className="font-weight-light font-italic float-right">{props.date}</p>
              <h2>{props.title}</h2>
              <p>{props.introduction}</p>
            </Col>
          </Row>
          <Row className="at_right">
            <p className="more_information">Czytaj więcej...</p>
          </Row>
          <hr />
        </Container>
      </article>
    </Link>
  )
}
export default Article;
