import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import Article from './article'
import { Container } from 'react-bootstrap'

const ArticleList = () => (

  <StaticQuery
    query = { graphql `query {
       allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
         totalCount
         edges {
           node {
             fields {
                slug
             }
             id
             frontmatter {
               title
               date(formatString: "MMMM YYYY", locale: "pl")
               timage{
                 childImageSharp {
                   gatsbyImageData(
                     formats: [AUTO, WEBP, AVIF]
                   )
                }
              }
              introduction
             }
             excerpt(pruneLength: 250)
           }
         }
       }
     }`
    }

  render = { data => (
     <Container fluid className="mt-5">
        {data.allMarkdownRemark.edges.map(({ node }) => (
           <Article id={node.id}
              to={node.fields.slug}
              timage={node.frontmatter.timage}
              introduction={node.frontmatter.introduction}
              title={node.frontmatter.title}
              date={node.frontmatter.date}
              excerpt={node.excerpt}/>
        ))}
     </Container>
  )}
 />
)

export default ArticleList;
